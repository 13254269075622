<template>
  <div style="height: 60px">
    <b-form-group v-if="habilitaSelecaoInstituto" label="Unidade Gestora Selecionada" label-for="instituto">
      <div v-if="carregandoInstituto" class="institutoStyle">
        <b-spinner />
      </div>

      <v-select
        v-else
        name="instituto"
        v-model="dados.institutoId"
        v-on:input="alterarInstituto"
        :options="instituto"
        :reduce="(option) => option.id"
        label="sigla"
        :clearable="false"
        :loading="carregandoInstituto"
      >
        <template #no-options>
          <div class="my-no-options-message">Nenhum registro encontrado!</div>
        </template>
      </v-select>
    </b-form-group>

    <h3 v-else class="d-flex justify-content-center align-items-center h-100" style="color: var(--primary)">
      {{ userData.Instituto.sigla }}
    </h3>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData } from '@/libs/utils.js'
  import vSelect from 'vue-select'
  import { detectarNavegador } from '@/libs/utils'

  export default {
    components: {
      formatarData,
      vSelect,
    },
    data() {
      return {
        dados: {},
        instituto: [],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        carregandoInstituto: false,
      }
    },
    async mounted() {
      if (this.userData.institutoSelecionado) {
        this.carregaLogoSistema(this.userData.institutoSelecionado)
        this.carregarInstituto(this.userData.institutoSelecionado)
      }

      this.habilitaSelecaoInstituto()

      this.$root.$on('selecionarInstitutoNavBar', (institutoId) => {
        if (institutoId) {
          this.carregaLogoSistema(this.userData.institutoSelecionado)
          this.carregarInstituto(institutoId)
        }
      })
    },
    methods: {
      habilitaSelecaoInstituto() {
        this.dados.institutoId = this.userData.Instituto ? this.userData.Instituto.id : undefined
        if (this.userData.role == 'master') {
          return true
        } else if (this.userData.role == 'admin' && this.userData.institutosVinculados.length > 1) {
          return true
        } else {
          return false
        }
      },
      carregarInstituto() {
        this.carregandoInstituto = true
        if (this.userData.role == 'admin') {
          useJwt
            .get(`cadastro/instituto/GetInstitutoPorUser/${this.userData.id}`)
            .then((response) => {
              if (this.userData.institutosVinculados.length > 1) {
                this.instituto = response.data.Instituto
              } else {
                this.instituto = this.userData.Instituto
              }
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.carregandoInstituto = false
            })
        } else {
          useJwt
            .get(`cadastro/instituto`)
            .then((response) => {
              this.instituto = response.data
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.carregandoInstituto = false
            })
        }
      },
      async alterarInstituto() {
        const dadosParaAtualizar = {
          userData: this.userData,
          institutoId: this.dados.institutoId,
          grupoAcessoId: null,
        }
        const updatedUserData = (await useJwt.post('auth/GetAtualizaUserData', dadosParaAtualizar)).data
        localStorage.setItem('userData', updatedUserData)

        this.$root.$emit('loadingMenu', true)

        await this.registarLogAcesso()
        await this.carregaLogoSistema(this.dados.institutoId)

        this.atualizarInformacoesTelaPrincipal()
      },

      registarLogAcesso() {
        try {
          let navegadorUtilizado = detectarNavegador()
          const dadosLogAcesso = {
            institutoId: this.dados.institutoId,
            email: this.userData.email,
            ip: window.globalIP,
            descricao: navegadorUtilizado,
          }
          useJwt.post('auth/LogAcesso', dadosLogAcesso)
        } catch (error) {
          console.error(error)
        }
      },
      atualizarInformacoesTelaPrincipal() {
        this.$emit('selecionarInstitutoEvent', true)
        this.$root.$emit('selecionarInstitutoNavBar', this.dados.institutoId)
        this.$root.$emit('atualizarItemsMenu')
        this.$root.$emit('atualizarLogoMenu', true)
        this.$root.$emit('atualizarGraficos3', true)
        this.$root.$emit('atualizarGraficos2', true)
        this.$root.$emit('atualizarGraficos1', true)
        this.$router.go()
      },
      carregaLogoSistema(institutoId) {
        if (!institutoId)
          return localStorage.setItem(
            'images',
            JSON.stringify({
              logo: null,
              logoMarca: null,
              logoRelatorio: null,
              logoCalculadora: null,
            }),
          )

        useJwt
          .get(`auth/carregarLogosSistema/${institutoId}`)
          .then((response) => {
            const logos = {
              logo: response.data.logo || null,
              logoMarca: response.data.logoMarca || null,
              logoRelatorio: response.data.logoRelatorio || null,
              logoCalculadora: response.data.logoCalculadora || null,
            }
            localStorage.setItem('images', JSON.stringify(logos))
            this.$root.$emit('atualizarLogoMenu', true)
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .institutoStyle {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
