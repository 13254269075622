<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="d-flex align-items-center text-center">
              <!-- Home Breadcrumb -->
              <b-breadcrumb-item to="/" class="breadcrumb-home">
                <feather-icon icon="HomeIcon" size="24" />
              </b-breadcrumb-item>

              <!-- Dynamic Breadcrumbs -->
              <b-breadcrumb-item
                v-for="(item, index) in getBreadcrumbItems"
                :key="index"
                :active="index === getBreadcrumbItems.length - 1"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  export default {
    computed: {
      getBreadcrumbItems() {
        // Se o breadcrumb na meta for uma função, chamá-la com a rota atual
        if (typeof this.$route.meta.breadcrumb === 'function') {
          return this.$route.meta.breadcrumb(this.$route)
        }
        return this.$route.meta.breadcrumb || []
      },
    },
  }
</script>

<style>
  .breadcrumb:not([class*='breadcrumb-']) .breadcrumb-item + .breadcrumb-item:before {
    background-size: 1.5rem;
  }
</style>
